<template>
  <el-dialog
    :title="isEdit ? '修改项目' : '添加项目'"
    :visible.sync="dialogVisible"
    width="678px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
      <el-row>
        <el-col :span="12" v-if="!isEdit && role === 100">
          <el-form-item label="运营商：" prop="id_operator">
            <el-select
              v-model="form.id_operator"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in operatorList"
                :key="item.id_operator"
                :label="item.name_operator"
                :value="item.id_operator"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目名称：" prop="name_project">
            <el-input
              v-model.trim="form.name_project"
              maxlength="16"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人：" prop="name_chief">
            <el-input v-model.trim="form.name_chief" maxlength="4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式：" prop="tel">
            <el-input v-model.trim="form.tel" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目地址：" prop="addr">
            <el-input v-model.trim="form.addr" maxlength="24"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddProject, UpdateProject, GetOperatorList } from '@/api'

const checkTel = (rule, value, callback) => {
  const reg = /^1[0-9]{10}$/
  if (!reg.test(value)) {
    callback(new Error('手机号输入错误'))
  }
  callback()
}

export default {
  data() {
    return {
      // 是否显示对话框
      dialogVisible: false,
      // 编辑还是新增
      isEdit: false,
      // 运营商列表
      operatorList: [],
      // 表单
      form: {
        id_operator: '',
        id: '',
        name_project: '',
        name_chief: '',
        tel: '',
        addr: ''
      },
      // 规则
      rules: {
        id_operator: [
          { required: true, message: '请选择运营商', trigger: 'change' }
        ],
        name_project: [
          {
            required: true,
            message: '请输入项目名称',
            trigger: 'blur'
          }
        ],
        name_chief: [
          {
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          }
        ],
        tel: [
          {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          },
          {
            validator: checkTel,
            trigger: 'blur'
          }
        ],
        addr: [
          {
            required: true,
            message: '请输入项目地址',
            trigger: 'blur'
          }
        ]
      },
      role: ''
    }
  },
  methods: {
    // 添加项目
    add() {
      this.isEdit = false
      this.dialogVisible = true
      this.role = this.$store.getters.role
      if (this.role === 100) {
        this.getOperatorList()
      }
    },

    // 获取运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorList()
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    },

    // 编辑项目
    edit(row) {
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.form.id = row.id
        this.form.name_project = row.name_project
        this.form.name_chief = row.name_chief
        this.form.tel = row.tel
        this.form.addr = row.addr
      })
    },

    // 重置表单
    resetForm() {
      this.$refs.formRef.resetFields()
    },

    // 确定
    confirm() {
      this.$refs.formRef.validate((_) => {
        if (!_) return
        if (this.isEdit) {
          this.confirmEdit()
        } else {
          this.confirmAdd()
        }
      })
    },

    // 确定添加
    async confirmAdd() {
      const form = this.form
      const params = {
        name_project: form.name_project,
        name_chief: form.name_chief,
        tel: form.tel,
        addr: form.addr
      }
      if (this.role === 100) {
        params.id_operator = form.id_operator
      }
      const { ret, data, msg } = await AddProject(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加项目失败!')
      }
      this.$message.success('添加项目成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 确定修改
    async confirmEdit() {
      const form = this.form
      const params = {
        id_project: form.id,
        name_project: form.name_project,
        name_chief: form.name_chief,
        tel: form.tel,
        addr: form.addr
      }
      const { ret, data, msg } = await UpdateProject(params)
      if (ret !== 0) {
        return this.$message.error(msg || '修改项目失败!')
      }
      this.$message.success('修改成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
