<template>
  <el-dialog
    title="请先初始化项目下系统"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
  >
    <div class="table_box">
      <div class="title">{{ title }}</div>
      <div class="content">
        <div class="item" v-for="(item, index) in deviceList" :key="index">
          <template v-if="item">
            <el-checkbox v-model="item.selected" @change="checkIsSelectAll">{{
              item.name
            }}</el-checkbox>
          </template>
        </div>
        <div class="item" v-for="item in len" :key="-item"></div>
      </div>
      <div class="row">
        <el-checkbox v-model="selectAll" @change="selectAllChange"
          >全选</el-checkbox
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { InitConfig } from '@/api'
export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 标题
      title: '',
      // 设备列表
      deviceList: [],
      // 填补空缺
      len: 0,
      // 是否全部选中
      selectAll: false
    }
  },
  methods: {
    open(row) {
      this.title = row.name_project
      const deviceList = JSON.parse(JSON.stringify(row.device_list))
      deviceList.map((i) => {
        i.selected = true
      })
      const emptyLength = deviceList.length % 4
      for (let i = 0; i < emptyLength; i++) {
        deviceList.push('')
      }
      this.deviceList = deviceList
      this.len = 4 - (deviceList.length % 4)
      this.checkIsSelectAll()
      this.dialogVisible = true
    },

    // 校验 是否全部选中
    checkIsSelectAll() {
      let count = 0
      let num = 0
      this.deviceList.forEach((i) => {
        if (i !== '') {
          num++
        }
        if (i.selected === true) {
          count++
        }
      })
      if (num === count) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },

    // 全选发生变化
    selectAllChange(flag) {
      this.deviceList.map((i) => {
        if (i) {
          i.selected = flag
        }
      })
    },

    // 确定初始化
    async confirm() {
      const selected = this.deviceList.filter((i) => {
        return i !== '' && i.selected === true
      })
      if (!selected.length) {
        return this.$message.warning('暂无选中系统!')
      }
      const ids = []
      selected.map((i) => {
        ids.push(i.id_device)
      })
      const params = {
        device_array: ids
      }
      const { ret, data, msg } = await InitConfig(params)
      if (ret !== 0) {
        return this.$message.error(msg || '批量初始化失败!')
      }
      this.$message.success('初始化成功')
      this.$emit('refresh')
      this.dialogVisible = false
    }
  }
}
</script>

<style lang='scss' scoped>
.table_box {
  border: 1px solid #eff2f7;
  border-radius: 4px;

  .title {
    height: 50px;
    border-bottom: 1px solid #eff2f7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: rgb(250, 250, 251);
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5b667f;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .item {
      flex: 0 0 25%;
      height: 50px;
      border-bottom: 1px solid #eff2f7;
      display: flex;
      align-items: center;
      padding-left: 24px;

      &:nth-child(3n + 1),
      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        border-right: 1px solid #eff2f7;
      }
    }
  }

  .row {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 24px;
  }
}
</style>
